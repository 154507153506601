<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">订单中心</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">合作课订单列表</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl">
          <div class="searchbox">
            <div title="课程名称" class="searchboxItem ci-full">
              <span class="itemLabel">订单编号:</span>
              <el-input
                v-model="searchData.orderNo"
                size="small"
                placeholder="请输入查询订单编号"
                clearable
              />
            </div>
            <div title="创建时间" class="searchboxItem ci-full">
              <span class="itemLabel">创建时间:</span>
              <el-date-picker
                v-model="searchData.exportDate"
                size="small"
                type="daterange"
                style="width: 5rem"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
              />
            </div>
            <div title="订单类型" class="searchboxItem ci-full">
              <span class="itemLabel">订单类型:</span>
              <el-select size="small" v-model="searchData.souOrderType" clearable placeholder="请选择订单类型">
                <el-option
                  v-for="item in orderTypeOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </div>
            <div class="df" style="padding-right: 10px">
              <el-button type="primary" class="bgc-bv" round @click="getData()"
                >查询</el-button
              >
              <el-button
                type="primary"
                class="bgc-bv"
                round
                @click="exportData()"
                >导出</el-button
              >
            </div>
          </div>
        </div>
        <div class="amountSummary">实付金额总收益：<span>￥{{allOrderMoney}}</span></div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
            >
              <el-table-column
                label="序号"
                align="center"
                fixed
                type="index"
                :index="indexMethod"
              />
              <el-table-column
                label="订单编号"
                align="center"
                width="185"
                prop="souOrderCode"
              />
              <el-table-column
                label="实付金额"
                align="center"
                prop="azPaymentMoney"
              />
              <el-table-column
                label="购买人手机号"
                align="center"
                min-width="120"
                prop="orderPhone"
              />
              <el-table-column
                label="订单状态"
                align="center"
                prop="orderState"
              />
              <el-table-column
                label="创建时间"
                align="center"
                prop="createTime"
              />
              <el-table-column
                label="课程名称"
                prop="courseName"
                show-overflow-tooltip
              />
              <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                  <el-button
                    type="text"
                    style="padding: 0px 5px"
                    size="mini"
                    @click="viewData(scope.row.souOrderId)"
                    >查看</el-button
                  >
                </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
    <el-dialog
      :visible.sync="dialogVisible"
      title="订单详情"
      width="40%"
      top="2%"
      center
      @close="closeDialog"
    >
      <div class="orderDetail">
        <div class="order_success">
          <div style="display: flex; align-items: center">
            <i class="el-icon-success" style="font-size: 16px"></i>
            <span class="orderStatus">已完成</span>
          </div>
        </div>
        <div class="order_form">
          <h4>订单信息</h4>
          <!-- <el-divider class="divider"></el-divider> -->
          <div class="df flexwp orderBox">
            <div class="df">
              <span>订单编号：</span>
              <span>{{ detailData.souOrderCode }}</span>
            </div>
            <div class="df">
              <span>创建时间：</span>
              <span>{{ detailData.createTime }}</span>
            </div>
            <div class="df">
              <span>实付金额：</span>
              <span class="money">￥{{ detailData.azPaymentMoney }}</span>
            </div>
          </div>
            <el-divider class="divider"></el-divider>
          <h4>买家信息</h4>
          <div class="df flexwp orderBox">
            <div class="df">
              <span>手机号：</span>
              <span>{{ detailData.orderPhone }}</span>
            </div>
          </div>
           <el-divider class="divider"></el-divider>
          <h4 style="padding:12px 0">商品信息</h4>
          <el-table
            ref="multipleTable"
            size="small"
            tooltip-effect="dark"
            style="width: 100%"
            stripe
            :header-cell-style="tableHeader"
            :data="detailTable"
          >
            <el-table-column
              label="课程名称"
              align="left"
              show-overflow-tooltip
              prop="courseName"
            />
            <el-table-column
              label="价格"
              align="left"
              show-overflow-tooltip
              prop="azPaymentMoney"
            >
              <template slot-scope="scope">
                <span style="color: red">￥{{ scope.row.azPaymentMoney }}</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue"; //引入表格无数据提示
import PageNum from "@/components/PageNum.vue"; //引入分页-组件
import List from "@/mixins/List"; //引入列表 - 混入
import { resetKeepAlive } from "@/utils/common"; //缓存页面
import { mapGetters } from "vuex";
export default {
  name: "cooperativeCourse/cooperativeCourseLibrary",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      //检索条件
      searchData: {
        //订单编号
        orderNo: "",
        //创建时间和结束时间
        exportDate: [],
        // 订单状态
        souOrderType:""
      },
      tableData: {},
      //弹窗是否打开
      dialogVisible: false,
      //弹窗详情信息
      detailData: {},
      detailTable: [],
      // 订单类型
      orderTypeOptions:[
        {
            label:"付费",
            value:"10"
        },
        {
            label:"免费",
            value:"20"
        }
      ],
    //   实付金额汇总
      allOrderMoney:0
    }
  },
  created() {},
  mounted() {},
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
      userJson: "getUser",
    }),
  },
  methods: {
    //获取列表数据
    getData(pageNum = 1) {
      const params = {
        pageNum,
        pageSize: this.pageSize,
      };
      if (this.searchData.exportDate) {
        params.creatDateFront = this.searchData.exportDate[0];
        params.creatDateAfter = this.searchData.exportDate[1];
      }
      if (this.searchData.orderNo) {
        params.souOrderCode = this.searchData.orderNo;
      }
      if(this.searchData.souOrderType){
        params["souOrderType"]=this.searchData.souOrderType;
      }
      this.doFetch({
        url: "/biz/souOrder/souOrderList",
        params,
        pageNum,
      });
      this.amountSummary();
    },
    // 获取 实付金额汇总
    async amountSummary(){
        const {searchData}=this;
        const params={
            souOrderCode:searchData.orderNo,
            creatDateFront:"",
            creatDateAfter:"",
            souOrderType:searchData.souOrderType
        }
        if (searchData.exportDate && searchData.exportDate.length>0) {
            params.creatDateFront = searchData.exportDate[0];
            params.creatDateAfter = searchData.exportDate[1];
        }
        const result=await this.$post("/biz/souOrder/allOrderMoney",params);
        this.allOrderMoney=result?.data?.allOrderMoney||0;
    },
    //导出数据
    exportData() {
      const params = {};
      if (this.searchData?.exportDate?.length) {
          (params.creatDateFront = this.searchData.exportDate[0]),
          (params.creatDateAfter = this.searchData.exportDate[1]);
      }

      if (this.searchData.orderNo) {
        params.souOrderCode = this.searchData.orderNo;
      }
      if(this.searchData.souOrderType){
        params.souOrderType=this.searchData.souOrderType;
      }
      this.$post("/biz/souOrder/souOrderExport", params).then((res) => {
        if (res.status == "0") {
          let list = [];
          list.push(res.data);
          for (let item of list) {
            if (!this.downloadItems.includes(item.taskId)) {
              this.$store.dispatch("pushDownloadItems", item.taskId);
            } else {
              this.$message.warning(
                "[" + item.fileName + "]已经申请下载,请耐心等待"
              );
            }
          }
        } else {
          this.$message.error(re.message);
        }
      });
    },
    //弹窗订单详情
    viewData(souOrderId) {
      this.dialogVisible = true;
      this.$post("/biz/souOrder/souOrderInfo", { souOrderId })
        .then((ret) => {
          this.detailData = {
            ...ret.data,
          };
          if (this.detailTable.length == 0) {
            this.detailTable.push(ret.data);
          }
        })
        .catch((err) => {
          return;
        });
    },
    closeDialog() {
        this.dialogVisible = false;
        this.detailData = {};
        this.detailTable = []
    },
    //获取高度
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 ) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 40;
      }
      this.tableHeight = tHeight;
    },
    beforeRouteLeave: resetKeepAlive,
  },
};
</script>
<style lang="less" scope>
.searchBox {
  padding: 0 !important;
  .el-icon-arrow-down:before {
    content: "\e6df" !important;
  }
}
.amountSummary{
    padding-left:12px;
    span{
        color:red;
    }
}
.orderDetail {
  .money {
    color: red;
  }
  .orderStatus {
    font-weight: 800;
    font-size: 16px;
    margin-left: 10px;
  }
  .order_success {
    padding: 10px;
    background-color: #70b602;
    // border: 1px solid rgb(255, 204, 0);
    color: #fff;
  }
  .order_form {
    margin-top: 15px;
    div.df {
      padding: 8px 0;
      span:first-child {
        // width: 6rem;
        text-align: left;
      }
      .money {
        color: red;
      }
    }
  }
}
.divider {
  margin: 12px 0;
}
.orderBox {
  div {
    width: 50%;
    align-items: center;
  }
}
</style>
